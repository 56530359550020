import styled from "styled-components";


export const PrivacyWrapper = styled.div`
  margin-bottom: 60px;
  .title {
    padding: 20px 0;
    text-align: center;
    color: #3C3C3C;
    font-size: 40px;
  }
  .sub-title {
    font-size: 24px;
    text-align: center;
  }
  ul {
    padding: 0 15px;
  }
  ul >li {
    &:nth-child(1){
      font-weight: 700;
      margin-bottom: 10px 0;
      font-size: 23px;
      color: #000;
      padding: 15px 0;
      line-height: 1.8;
    }
    color: #666;
  }
  .bold {
    font-weight: 700;
    color: #000;
  }
`