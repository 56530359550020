import React, { memo } from 'react'
import Logo from '@/assets/img/logo.png'
import { useNavigate } from 'react-router-dom'

import { HeaderWrapper, HeaderLeftWrapper, HeaderRightWrapper } from './styled'
import { Col, Row } from 'antd'

const Header = memo(() => {
  const navigate = useNavigate()

  const handleScrollBottom = () => {
    window.scrollTo(0, document.documentElement.scrollHeight)
  }

  const handleNavigateApp = () => {
    navigate('/app')
  }
  const handleNavigateHome = () => {
    navigate('/')
  }

  return (
   <HeaderWrapper>
    <Row className='wrap header' align="middle">
      <Col span={8} onClick={e => handleNavigateHome()}>
        <div className="left">
          <img className='logo' src={Logo} alt="" />
          <h1>零咔智能科技</h1>
        </div>
      </Col>
      <Col span={16} className="right">
        <div className='item'>了解梨卡</div>
        <div className='item' onClick={e => handleScrollBottom()}>公众号</div>
        <div className='item' onClick={e => handleScrollBottom()}>小程序</div>
        <div className='item' onClick={e => handleNavigateApp()}>APP</div>
        <div className='item' onClick={e => handleScrollBottom()}>联系我们</div>
      </Col>
    </Row>
   </HeaderWrapper>
  )
})

export default Header