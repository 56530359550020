import React from 'react'
import Main from '../pages/main'
import VipProtocal from '../pages/vip-protocal'

const Relate = React.lazy(() => import("../pages/relate"))
const Privacy = React.lazy(() => import('../pages/privacy'))
const NotFound = React.lazy(() => import("../pages/not-found"))
const App = React.lazy(() => import('../pages/app'))
const Clause = React.lazy(() => import('../pages/clause'))
const TakePhotoUsageDoc = React.lazy(() => import("../pages/takePhotoUsageDoc"))
const TakePhotoPrivacy = React.lazy(() => import("../pages/takePhotoPrivacy"))
const TakePhotoProtocol = React.lazy(() => import("../pages/takePhotoProtocol"))

const routes = [
  {
    path: '/',
    element: <Main />
  },
  {
    path: '/relate',
    element: <Relate />
  },
  {
    path: '/privacy',
    element: <Privacy />,
    meta: {
      isShowBoth: false
    }
  },
  {
    path: '/app',
    element: <App />
  },
  {
    path: '/clause',
    element: <Clause />,
    meta: {
      isShowBoth: false
    }
  },
  {
    path: '/vip_protocol',
    element: <VipProtocal />,
    meta: {
      isShowBoth: false
    }
  },
  {
    path: '/take_photo_usage_doc',
    element: <TakePhotoUsageDoc />,
    meta: {
      isShowBoth: false
    }
  },
  {
    path: '/take_photo_privacy',
    element: <TakePhotoPrivacy />,
    meta: {
      isShowBoth: false
    }
  },
  {
    path: '/take_service_protocol',
    element: <TakePhotoProtocol />,
    meta: {
      isShowBoth: false
    }
  },
  {
    path: '*',
    element: <NotFound />
  }
]

export default routes