import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';

import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import "lib-flexible/flexible"

import App from './App';

import store from './store'

import "normalize.css"
import "@/assets/css/index.css"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <Suspense fallback="loading~">
        <App />
      </Suspense>
    </Provider>
  </BrowserRouter>
);
