import React, { memo } from 'react'
import { PrivacyWrapper } from './style'

const VipProtocal = memo(() => {
  return (
    <PrivacyWrapper className='wrap'>
      <div className="title">梨卡相机会员协议</div>
      <p style={{padding: "20px 15px"}}>梨卡相机会员由梨卡相机的所有者开发，旨在为梨卡相机用户（以下更多的称之为「您」）提供付费的增值会员权益。请您务必审慎阅读、充分理解本协议各条款内容，特别是免除或限制责任的相应条款。 未成年人应在法定监护人的陪同下阅读本协议。您以任何形式获得并使用梨卡相机会员资格，都视为您已阅读并同意接受本协议的约束。如果您对本协议的任何条款表示异议，您可以选择不开通本产品。  </p>
      <ul>
        <li>1. 服务内容</li>
        <li><span className='bold'>梨卡相机会员</span>是梨卡相机为用户打造的增值产品，包含多种专有的会员权益。您使用梨卡相机时的设备或软件版本型号，可能影响您正常享受梨卡相机会员权益。当前述情况出现时，我们将建议您更换设备或提醒您及时升级，以免影响您正常享受全部会员权益。
梨卡相机会员所包含的会员权益以梨卡相机在会员介绍页公布的信息为准。 梨卡相机有权根据业务发展情况调整全部或部分会员权益。前述调整将在上述会员服务页面进行公告，您也可以随时向客服了解当下会员权益的具体情况。
</li>
      </ul>
      <ul>
        <li>2. 开通和终止</li>
        <li>•	您同意本协议并支付会员费用的方式，即可获得梨卡相机 会员资格（以下简称「会员资格」）。</li>
        <li>•	梨卡相机会员有效期：自会员资格开通之日起算，具体有效期限可以在个人页面查看。会员在有效期内续费或通过其他方式续展会员期限，有效期将相应延长。会员在会员资格到期后未以任何方式续展期限，梨卡相机有权停止提供相应会员权益。</li>
        <li>•	您在开通梨卡相机会员时，应仔细核对账号信息、会员期限、会员费用等信息。</li>
      </ul>
      <ul>
        <li>3. 会员费用及续费</li>
        <li>梨卡相机会员费以您购买梨卡相机会员时，梨卡相机在会员介绍页面标示的价格为准。</li>
        <li className='bold'>梨卡相机会员购买成功后，不支持退款。未使用的服务费用作为违约金归梨卡相机所有，您的相关用户权益的损失由您自行承担。</li>
      </ul>
    </PrivacyWrapper>
  )
})

export default VipProtocal