import styled from "styled-components"

export const ChangeCooperationWrapper = styled.div`
  margin-top: 50px;
  overflow: hidden;
  .content {
    padding: 30px 20px;
  }
  .inner {
    display: flex;
  }
  @media (max-width: 1170px) {
    .inner {
      display: block;
    }
    .left,
    .right  {
      padding-right: 0;
      padding: 20px 30px;
    }
  }
  .inner-content {
    display: flex;
    align-items: center;
    flex: 1;
    min-height: 400px;
    line-height: 2;
    .divider {
      height: 60px;
    }
    .desc {
      color: #666;
      font-size: 26px;
    }
  }
  .left {
      padding-right: 40px;
    }
  .animate {
    overflow: hidden;
    animation: fadeRight ease-in 300ms forwards;
  }
`