import styled from "styled-components";

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 22px 0;
  .left {
    flex: 1;
    display: flex;
    align-items: center;
    cursor: pointer;
    .logo {
      width: 60px;
      height: 60px;
    }
    h1 {
      color: #bce256;
    }
  }
  @media (max-width: 768px) {
      h1 {
        display: none;
      }
    }
  .right {
    display: flex;
    justify-content: space-evenly;
    width: 500px;
    .item {
      cursor: pointer;
    }
  }
`