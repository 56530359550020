import React, { memo } from 'react'
import { ConsultWrapper } from './style'

import Card from '@/components/card'
import { useAnimation } from '@/hooks'

import aiImg from '@/assets/img/ai.jpg'

const Consult = memo(() => {
  const cardRef = useAnimation()

  return (
    <ConsultWrapper className='wrap'>
        <div className="content" ref={cardRef}>
          <div className="left inner-content">
            <Card title='生成式AI营养咨询' className='nutrition' />
            <div className='desc1'>基于自研小模型的生成式A营养咨询师己向1200+位用户提供1000＋次服务;与杭州栗伙健康科技达成战略合作，双方共提供30+在线营养师提供1对1私人咨询服务</div>
          </div>
          <div className="right inner-content small-none">
            <img src={aiImg} alt="" />
          </div>
        </div>
    </ConsultWrapper>
  )
})

export default Consult