import React, { memo } from 'react'
import { CardWrapper } from './style'

const Card = memo((props) => {
  const { title, contentSlot } = props

  return (
    <CardWrapper>
      <div className="title">{title}</div>
      <div className="content">{contentSlot}</div>
    </CardWrapper>
  )
})

export default Card