import React, { memo } from 'react'
import { useRoutes, useLocation } from 'react-router-dom'


import routes from './router'

import Header from './components/header'
import Footer from './components/footer'



const App = memo(() => {
  const { pathname } = useLocation()
  const matchRoute = routes.find(item => item.path === pathname)
  let isShow = matchRoute?.meta?.isShowBoth
  if (isShow === undefined) isShow = true

  return (
    <div className='app'>
      {isShow && <Header  />}
      {useRoutes(routes)}
      {isShow && <Footer />}
    </div>
  )
})

export default App