import styled from "styled-components"

export const ManageWrapper = styled.div`
  padding: 50px 0;
  background-image: linear-gradient(to top, #bce256, #fff);
  overflow: hidden;
  .content {
    padding: 30px 20px;
    box-sizing: border-box;
    .inner {
      display: flex;
    }
    .inner-content {
      display: flex;
      align-items: center;
      flex: 1;
      color: #000;
      line-height: 2;
      .desc {
        font-size: 26px;
        color: #666;
      }
    }
    .left {
      justify-content: center;
      img {
        width: 450px;
        height: 450px;
      }
    }
    .right {
      text-align: left;
      margin-left: 20px;
      flex: 1.4;
      .divider {
        height: 60px;
      }
    }
  }
  @media (max-width: 1170px) {
    .content {
      .left {
        display: none;
      }
    }
  }
  .animate {
    overflow: hidden;
    animation: fadeLeft ease-in 300ms forwards;
  }
`