import styled from "styled-components";

export const FooterWrapper = styled.div`
  position: relative;
  margin-top: 100px;
  padding: 40px 0 60px 0;
  background-color: #222;
  color: #eee;
  text-align: center;
  .content {
    display: flex;
    justify-content: space-between;
    text-align: left;
    flex-wrap: wrap;
    padding: 0 15px;
  }
  .item {
    position: relative;
    width: 300px;
    margin-bottom: 20px;
    h2 {
      position: absolute;
      left: 0;
      right: 0;
      font-size: 28px;
      font-weight: 700;
      height: 60px;
    }
    .list {
      margin-top: 75px;
      >div {
        padding: 13px 0;
        font-size: 20px;
      }
    }
  }
  @media (max-width: 768px){
    .item {
      width: 50%;
    }
    .content {
    }
  }

  @media (max-width: 576px){
    .item {
      width: 100%;
    }
  }
  .center {
    box-sizing: border-box;
    .mini {
      display: flex;
      align-items: center;
    }
    img {
      margin-left: 10px;
      width: 100px;
      height: 100px;
    }
  }
  .right {
    img {
      margin: 0 10px 10px 0;
      width: 130px;
      height: 90px;
    }
  }
  .privacy {
    span {
      padding: 5px;
      cursor: pointer;
    }
  }
  .record {
    position: absolute;
    left: 50%;
    bottom: 10px;
    transform: translateX(-50%);
    color: #fff;
  }
`