import { useEffect, useRef } from 'react'

export function useAnimation () {
  // 可视区域动画
  const cardRef = useRef(null)
  const scrollFn = () => {
    const clientHeight = document.documentElement.clientHeight
    const scrollTop = document.documentElement.scrollTop
    if (cardRef.current) {
      const elOffsetTop = cardRef.current.offsetTop
      if (elOffsetTop <= clientHeight + scrollTop) {
        if (!cardRef.current.classList.contains('animate')) {
          cardRef.current.classList.add('animate')
        }
      } else {
        // cardRef.current.classList.remove('animate')
      }
    }
    
  }
  useEffect(() => {
    window.addEventListener('scroll', scrollFn)
    return () => {
      window.removeEventListener('scroll', scrollFn)
    }
  },[cardRef])

  return cardRef
}