import styled from "styled-components"
import banner from '@/assets/img/banner.png'


export const MainWrapper = styled.div`
  width: 100%;
  
  .main-img {
    position: relative;
    width: 100%;
    height: 800px;
    background-image: url(${banner});
    background-position: top;
    background-size: cover;
    color: #fff;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    
   
    .cover {
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, .4);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .content {
      width: 100%;
      margin: auto;
      overflow: hidden;
      animation: fadeIn 400ms linear;
      
    }
  }
  @media (min-width: 568px),(max-width: 568px) {
    .main-img {
      height: 260px;
    }
    .name {
      font-size: .875rem;
      padding-bottom: 15px;
    }
    .desc {
      font-size: .575rem;
      opacity: .8;
      letter-spacing: 10px;
    }
    .desc1 {
      font-size: .525rem;
      color: #666;
    }
    .small-none {
      display: none;
    }
  } 
  @media (min-width: 768px) {
    .main-img {
      height: 400px;
    }
    .name {
      font-size: 1.3rem;
      padding-bottom: 30px;
    }
    .desc {
      font-size: .775rem;
      opacity: .8;
      letter-spacing: 10px;
    }
    .desc1 {
      font-size: .525rem;
      color: #666;
    }
    .small-none {
      display: none;
    }
  } 
  @media (min-width: 1200px) {
    .main-img {
      height: 800px;
    }
    .name {
      font-size: 1.5rem;
      padding-bottom: 30px;
    }
    .desc {
      font-size: .875rem;
      opacity: .8;
      letter-spacing: 10px;
    }
    .desc1 {
      font-size: .525rem;
      color: #666;
    }
}
  .intro {
    margin-top: 20px;
    h1 {
      font-weight: 400;
      font-size: 0.8rem;
    }
    .desc {
      padding: 20px 0;
      text-align: center;
      .detail {
        font-size: .475rem;
        margin-top: 20px;
        color: #ccc;
        >div {
          line-height: 1.7;
        }
      }
    }
  }
  .cards {
    overflow: hidden;
    .card {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 50rpx;
      margin-top: 90px;
      .left {
        width: 37%;
        .title {
          font-size: 30px;
          font-weight: 700;
        }
        .desc {
          margin-top: 15px;
          font-size: 22px;
          >div {
            padding: 10px 0;
          }
        }
      }
      .right {
        flex: 1;
        display: flex;
        justify-content: space-between;
        img {
          width: 200px;
        }
      }
      &:nth-child(2) {
        .left {
          position: relative;
          left: 50px;
        }
      }
    }
    #two {
      .left {
        padding-left: 40px;
      }
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(-200px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`
