import React, { memo } from 'react'

import { ChangeCooperationWrapper } from './style'

import Card from '@/components/card'

import { useAnimation } from '@/hooks'

const ChangeCooperation = memo(() => {
  const cardRef  = useAnimation()

  return (
    <ChangeCooperationWrapper>
      <div className="inner wrap" ref={cardRef}>
        <div className="left inner-content">
          <div>
            <Card title='智能BI赋能传统业务数字化转型'></Card>
            <div className="desc1">
              <div>与林内、璞康科技达成三方合作，共研林内健康管理官，提升会员权益</div>
              <div>为上海20万+林内用户家庭提供健康管理服务</div>
            </div>
          </div>
        </div>
        <div className="right inner-content">
          <div>
            <Card title='医院合作 传统转型'></Card>
            <div className="desc1">
              <div>与湖南湘雅医院营养科达成合作，为其提供术后患者营养饮食管理B1系统</div>
              <div>帮助营养科医师高效诊疗与病患管理</div>
              <div>将为4万+在院及离院病人提供营养管理咨询</div>
            </div>
          </div>
        </div>
      </div>
    </ChangeCooperationWrapper>
  )
})

export default ChangeCooperation