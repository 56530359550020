import styled from "styled-components"

export const ConsultWrapper = styled.div`
  padding: 50px 0;
  overflow: hidden;
  .content {
    padding: 30px 20px;
    display: flex;
    .inner-content {
      flex: 1;
      line-height: 2;
      .desc {
        color: #666;
      }
      img {
        width: 450px;
      }
    }
    .left {
      font-size: 26px;
      .box {
        margin-top: 40px;
        width: 400px;
        height: 200px;
        box-shadow: 0 10px 10px rgba(0,0,0,.1);
      }
    }
    .right {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 20px;
      flex: 1.4;
    }
  }

  @media (max-width: 1170px) {
    .content {
      .right {
        display: none;
      }
    }
  }
  .animate {
    overflow: hidden;
    animation: fadeRight ease-in 300ms forwards;
  }
`